import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

const ProjectHelse = ({ data, location }) => {
  return (
    <Layout>
      <SEO title="Migraciones a Drupal 8 | asdloop" description="Tenemos varios años de experiencia migrando sitios a Drupal 8 desde cualquier de sus versiones. Contacta con nosotros y te ayudaremos a migrar tu sitio a Drupal 8." />
  <section className="jumbotron bg-transparent pb-5" id="header">
    <div className="container">
      <div className="row align-items-center text-center">
        <h1 className="font-weight-bold display-4 pl-0 p-1 col-md-9 offset-md-2 col-sm-12">Expertos en <span class="text-primary">migraciones</span> a Drupal 8</h1>
        <p className="lead mb-4 col-md-8 offset-md-2 col-sm-12">Fuimos de las primeras agencias en España en comenzar a trabajar con Drupal 8 en 2016 y estamos especializados en realizar migraciones a Drupal 8 desde cualquiera de sus versiones.</p>
      </div>
    </div>
  </section>
  <section className="jumbotron pb-5 pt-5 bg-light text-left" id="features">
    <div className="container">
      <div className="row text-left">
        <div className="col col-md-9 offset-md-2 col-sm-12">
          <h2>
            <strong className="text-left">Cómo realizar una migración a Drupal 8</strong>
          </h2>
          <p className="lead pt-4">Una migración a Drupal 8 estándar requiere hacer una serie de tareas, aparte de la lógica de negocio e integraciones a medida que pueda ser necesario desarrollar en el proyecto.</p>
          <h3 className="pt-2">Instalación limpia de Drupal 8</h3>
          <p className="lead">A diferencia de la migración de D6 a D7, no se reutiliza la misma base de datos, sino que se hace sobre una instalación limpia de Drupal 8, donde luego se migra tanto la configuración como el contenido.</p>
          <h3 className="pt-2">Análisis</h3>
          <p className="lead">Analizar el alcance de la migración, viendo que elementos que vamos a migrar y cuales vamos a desechar.</p>
          <h3 className="pt-2">Migrar la configuración</h3>
          <p className="lead">Aquí entran todos los tipos de contenidos, taxonomías, entidades, vistas, estilos de imagen, etc. Con el módulo «migrate_update» se puede automatizar bastante el proceso, aunque siempre hay componentes sin «migration paths» y hay que intervenir manualmente.</p>
          <h3 className="pt-2">Migrar el contenido</h3>
          <p className="lead">Usar migrate para migrar todos los contenidos. Si no hay campos custom o algún campo especial definido por un módulo contribuido, la migración suele ser bastante directa, escribiendo un fichero YML (por entidad) con los mapeos de campos.</p>
          <h3 className="pt-2">Migrar los módulos custom</h3>
          <p className="lead">Revisar si existen los módulos contribuidos en Drupal 8 (o si han sido absorbidos por el core) y si no, buscar alternativas.</p>
          <h3 className="pt-2">Migrar el theme</h3>
          <p className="lead">Hay que migrar el theme gráfico a Drupal 8 con todo lo que ello conlleva: plantillas twig y nuevas clases CSS.</p>
        </div>
      </div>
    </div>
  </section>
  <section className="jumbotron pb-5 pt-5 text-left bg-transparent" id="features">
    <div className="container">
      <div className="row text-left">
        <div className="col col-md-9 offset-md-2 col-sm-12">
          <h2>
            <strong className="text-left">Ventajas de migrar a Drupal 8</strong>
          </h2>
          <p className="lead pt-4">Drupal 8 supuso un cambio de enfoque en la arquitectura y la manera de desarrollar con Drupal.</p>
          <h3 className="pt-2">Componentes symfony</h3>
          <p className="lead">Esta ha sido una de las grandes novedades que ha permitido a Drupal 8 pasar al siguiente nivel. La incorporación de componentes Symfony integradas en Drupal 8 ha supuesto un cambio de paradigma para los desarrolladores, gracias a su enfoque procedimental mucho más eficiente.</p>
          <h3 className="pt-2">Gestión multi-idioma</h3>
          <p className="lead">Comparado con las versiones anteriores de Drupal, la gestión multi-idioma es la noche y el día. El sistema de traducción en Drupal 8 está integrado dentro del Core del CMS. La última versión tiene configurado un módulo de traducción muy potente, pudiendo traducir fácilmente la interface, el contenido y la configuración.</p>
          <h3 className="pt-2">Edición en línea /WYSIWYG core</h3>
          <p className="lead">El editor WYSIWYG (What You See is What You Get) es una nueva característica que le da la capacidad a los editores de contenido mucha más versatilidad en el front-end. El gestor de contenidos de la web puede ver directamente cómo se va a visualizar el contenido antes de publicarlo.</p>
          <h3 className="pt-2">Theming</h3>
          <p className="lead">En Drupal 8 Twig sustituye a PHPTemplate como motor de plantillas por defecto. Twig es un lenguaje de plantillas compilado basado en PHP, aporta una mejor separación entre lógica y display (ya no hay código php embebido en las templates). Cuando tu página se renderiza, el motor de Twig toma la plantilla y la compila una única vez, y la almacena en caché para mejorar el rendimiento.</p>
          <h3 className="pt-2">Bloques</h3>
          <p className="lead">Los bloques siempre han formado parte de Drupal, pero con Drupal 8 se incorporan por fin en el Core, y ya no es necesario utilizar módulos contribuidos para que fueran usables. Ahora los bloques custom y el resto de bloques pueden ser utilizados más de una vez.</p>
          <h3 className="pt-2">Gestión de configuraciones</h3>
          <p className="lead">Drupal 8 incorpora una gestión de configuraciónes en el Core, que permite exportar la configuración completa del sitio en un archivo de configuración. Ya no es necesario utilizar módulos adicionales para desplegar una configuración de un entorno a otro. Toda la configuración se gestiona en archivos YAML, desde la lista de módulos habilitados, hasta los tipos de contenido, vocabularios de taxonomía, campos y vistas. Exportar e importar cambios de configuración entre un entorno y otro puede hacerse usando comandos Drush y/o de Consola Drupal o Administrador de configuración.</p>
        </div>
      </div>
    </div>
  </section>
  <section className=" pb-5 pt-5 bg-light text-left" id="features">
    <div className="container">
      <div className="row text-left">
        <div className="col col-md-9 offset-md-2 col-sm-12">
          <h2>
            <strong className="text-left">Migrar a Drupal 8 o esperar a Drupal 9</strong>
          </h2>
          <p className="lead pt-4">¿Tienes una web en Drupal 7 y no sabes qué hacer? Drupal 7 y Drupal 8 van a dejar de recibir soporte en Noviembre de 2021, así que tienes 3 opciones:</p>
          <h3 className="pt-2">Buscar soporte extendido a tu Drupal 7</h3>
          <p className="lead">No te lo recomendamos. Vas a pagar a una empresa por mantener un sitio antiguo. No te beneficiaras de mejoras de la comunidad ni de las mejoras del Core de Drupal (ya sea 8 ó 9).           <br></br>El soporte extendido se limitará a aplicar parches de seguridad para que tu sitio no sea vulnerable.</p>
          <h3 className="pt-2">Migrar a Drupal 8</h3>
          <p className="lead">Drupal también dejará de recibir soporte de la comunidad en Noviembre de 2021. ¿Entonces por qué lo proponéis como opción? Porque la migración de 8 a 9 será una migración menor. La migración "real" es de 7 a 8.           <br></br>Si migras a Drupal 8 ahora, te beneficiarás de sus mejoras hasta que tengas que migrar a Drupal 9, que será como actualizar a la versión 8.X.<br></br>Además, no te verás obligado a adoptar una versión demasiado temprana de Drupal 9. Podrás esperar con Drupal 8 hasta que el 9 sea lo suficientemente estable.<br></br><strong>Es la opción que te recomendamos</strong></p>
          <h3 className="pt-2">Migrar a Drupal 9</h3>
          <p className="lead">La fecha para el primer release es Junio de 2020. Las primeras versiones de todos los software son menos estables, es recomendable esperar un tiempo producencial, a no ser que seas un early-adopter. El volumen de actualizaciones de seguridad puede ser elevado. <br></br><strong>No te recomendamos esperar a Drupal 9</strong>, nuestra opción ideal sería Drupal 7 -> Drupal 8 en 2020 -> Drupal 9 en 2021.</p>
        </div>
      </div>
    </div>
  </section>

    </Layout>
  )
}

export default ProjectHelse
